<template>
    <div class="row">
        <div class="col-12" ref="readerContainer">
            <!-- <div class="viewport scanner"> -->
                <!-- <div class="canvas" style="background-color: red;" :style="{width: readerSize.width + 'px', height: readerSize.height + 'px'}" > -->
            <div >
                <QuaggaScanner v-if="showReader" :readerSize="readerSize" :onDetected="onDecode"/>
                    <!-- <v-quagga v-if="showReader" :onDetected="logIt" :readerSize="readerSize" :readerTypes="['ean_reader']"></v-quagga> -->
            </div>
            <!-- </div> -->
        </div>
        <div class="col-md-12 py-2">
            <span class="font-weight-bold">Códigos cámara</span>
        </div>
        <div class="col-md-12">
            <div v-if="resultados.length > 0" class="funkyradio">
                <div class="funkyradio-success" v-for="(resultado) in resultados" :key="resultado" >
                    <input v-model="seleccionado" type="radio" name="radio" :id="'radio' + resultado" :value="resultado" />
                    <label :for="'radio' + resultado" class="mb-1 mt-0">
                        {{ resultado }}
                    </label>
                </div>
            </div>
            <div v-else>
                <span class="font-italic">
                    Sin códigos
                </span>
            </div>
        </div>
    </div>
</template>

<script>
import { library } from '@fortawesome/fontawesome-svg-core';
import { faUserSecret, faSave, faArrowLeft} from '@fortawesome/free-solid-svg-icons';
import QuaggaScanner from '../Barcode/QuaggaScanner.vue';
import _ from "lodash";

library.add([faUserSecret, faSave, faArrowLeft]);

export default {
    name: 'BarcodeReader',
    components: {
        QuaggaScanner
    },
    data: () => {
        return {
            resultados: [],
            // resultados: [1,2],
            timmer: null,
            seleccionado: null,
            showReader: false,
            readerSize: {
                width: 640,
                height: 480
            },
        }
    },
    watch: {
        seleccionado: function(valor) {
            if(valor) {
                this.$emit('codigo', valor);
            }
        }
    },
    created() {
        this.$emit('codigo', null);
        window.addEventListener("resize", this.getDimensions);
    },
    destroyed() {
        // this.$emit('codigo', null);
        window.removeEventListener("resize", this.getDimensions);
    },
    methods: {
        onDecode (data) {            
            if(!this.resultados.includes(data.codeResult.code)) {
                alert('detected.codeResult: ' + JSON.stringify(data.codeResult.code));
                this.resultados.push(data.codeResult.code);
            }

            // if (this.timmer) clearTimeout(this.timmer);
            // this.timmer = setTimeout(() => {

            //     if (this.text === a) {
            //         this.text = "";
            //     }

            // }, 2000);

            // console.log('detected', data)
        },
        onLoaded() {
            console.log("load");
        },
        getDimensions: _.debounce(function() {
            // this.width = document.documentElement.clientWidth;
            // this.height = document.documentElement.clientHeight;

            this.readerSize = {
                height: this.$refs.readerContainer.clientHeight - 100,
                width: this.$refs.readerContainer.clientWidth - 20
            }

            setTimeout(() => {  
                this.showReader = true;
            }, 200);
        }, 1000)
    },
    mounted() {
        const self = this;

        setTimeout(() => {
            self.getDimensions();
        }, 200);
    }
}
</script>
<style scoped>

</style>
