<template>
    <b-modal 
        v-model="showModal" 
        id="modal-lg" 
        size="lg" 
        title="Producto"
        :footer-class="'justify-content-between'"
        ref="modal"
        @hide="onHidden"
        >
        <ProductosForm 
            :save.sync="save" 
            :isModal="true" 
            @entity="dataSaved" 
            :triggerBuscarJumbo="triggerBuscarJumbo"
            :id-to-edit="formIdToEdit" 
            :codigo="codigo"
        />
        <template #modal-footer>
            <button type="button" class="btn btn-outline-dark" @click="hideModal">Cerrar</button>
            <div>
                <button type="button" class="btn btn-jumbo mr-1" @click="buscarJumbo" v-loading-btn="{loading: loading}">
                    Buscar
                </button>
                <!-- &nbsp; -->
                <button type="button" class="btn btn-success" @click="submit()" v-loading-btn="{loading: loading}">
                    <font-awesome-icon icon="save" /> Guardar producto
                </button>
            </div>
        </template>
    </b-modal>
</template>
<script>
import { library } from '@fortawesome/fontawesome-svg-core';
import { faSave } from '@fortawesome/free-solid-svg-icons';
import ProductosForm from './ProductosForm.vue';

library.add([ faSave ]);

export default {
	name: 'ModalProductosForm',
    components: {
        ProductosForm
    },
    props: {
        show: {
            type: Boolean,
            default: false
        },
        idToEditModal: {
            type: String,
            default: null
        },
        codigo: {
            type: String,
            default: null
        }
    },
	data: function() {
		return {
            formIdToEdit: null,
            showModal: this.show,
            save: false,
            loading: false,
            triggerBuscarJumbo: null
		}
	},
    watch: {
        show: function (nuevo) {
            this.showModal = nuevo;
        },
        save: function(nuevo) {
            this.loading = nuevo;
        },
        idToEditModal: function(id) {
            let self = this;
            self.formIdToEdit = id;
        },
        // codigo: function(codigo) {
            // console.log('codigo modal-prodcutos-form', codigo);
        // }
    },
	methods: {
        buscarJumbo() {
            console.log('click buscarJumbo!');
            this.triggerBuscarJumbo = this.codigo;
            // this.triggerBuscarJumbo = true;
        },
        onHidden() {
            this.$emit('update:show', false);
            this.$emit('closed', true);
        },
        hideModal() {
            this.$refs['modal'].hide()
        },
        submit() {
            let self = this;
            self.save = true;
        },
        dataSaved(entity) {
            this.$emit('entity', entity);
            this.hideModal();
        }
	},
	mounted: function() {
	}
}
</script>