<template>
  <div id="interactive" class="viewport">
    <!-- <video :style="{width: readerSize.width + 'px', height: readerSize.height + 'px'}"/> -->
    <!-- <video :style="{width: readerSize.width + 'px', height: readerSize.height + 'px'}"/> -->
    <!-- <canvas class="drawingBuffer" :width="readerSize.width" :height="readerSize.height"/> -->
    <!-- <canvas class="drawingBuffer"/> -->
  </div>
</template>

<script>
import Quagga from 'quagga';
import _ from "lodash";

export default {
  name: 'QuaggaScanner',
  props: {
    onDetected: {
      type: Function,
      default(result) {
        //   alert('detected: ' + JSON.stringify(result));
        console.log('detected: ', result);
      },
    },
    onProcessed: {
      type: Function,
      default: _.debounce(function(result) {
        let drawingCtx = Quagga.canvas.ctx.overlay;

        let drawingCanvas = Quagga.canvas.dom.overlay;

        if (result) {
          if (result.boxes) {
            drawingCtx.clearRect(
              0,
              0,
              parseInt(drawingCanvas.getAttribute('width')),
              parseInt(drawingCanvas.getAttribute('height'))
            );
            result.boxes
              .filter(function(box) {
                return box !== result.box;
              })
              .forEach(function(box) {
                Quagga.ImageDebug.drawPath(box, { x: 0, y: 1 }, drawingCtx, {
                  color: 'green',
                  lineWidth: 2,
                });
              });
          }
          if (result.box) {
            Quagga.ImageDebug.drawPath(result.box, { x: 0, y: 1 }, drawingCtx, {
              color: '#00F',
              lineWidth: 2,
            });
          }

          if (result.codeResult && result.codeResult.code) {
            Quagga.ImageDebug.drawPath(
              result.line,
              { x: 'x', y: 'y' },
              drawingCtx,
              { color: 'red', lineWidth: 3 }
            );
          }
        }
      }, 500),
    },
    readerTypes: {
      type: Array,
      default: () => [
          'code_128_reader', 'ean_reader', 
        //   'EAN'
        ],
    },
    readerSize: {
      type: Object,
      default: () => ({
        width: 640,
        height: 480,
      }),
      validator: o =>
        typeof o.width === 'number' && typeof o.height === 'number',
    },
    aspectRatio: {
      type: Object,
      default: () => ({
        min: 1,
        max: 2,
      }),
      validator: o => typeof o.min === 'number' && typeof o.max === 'number',
    },
  },
  data: function() {
    return {
      quaggaState: {
        inputStream: {
          type: 'LiveStream',
        //   size: 800
          constraints: {
            // width: this.readerSize.width,
            // height: this.readerSize.height,
            // width: { min: this.readerSize.width },
            width: { min: 640 },
            // height: { min: this.readerSize.height },
            height: { min: 480 },
            facingMode: 'environment',
            aspectRatio: { min: 1, max: 2 },
          },
        },
        locator: {
          patchSize: 'medium',
          halfSample: true,
        },
        numOfWorkers: 2,
        frequency: 20,
        decoder: {
          readers: this.readerTypes,
        },
        locate: true,
      },
    };
  },
  watch: {
    onDetected: function(oldValue, newValue) {
      if (oldValue) Quagga.offDetected(oldValue);
      if (newValue) Quagga.onDetected(newValue);
    },
    onProcessed: function(oldValue, newValue) {
      if (oldValue) Quagga.offProcessed(oldValue);
      if (newValue) Quagga.onProcessed(newValue);
    },
    readerSize: function(oldValue, newValue) {
        console.log('watch readerSize');
        this.quaggaState.inputStream.constraints.width = newValue.width;
        // this.quaggaState.inputStream.constraints.width = { min: newValue.width };
        this.quaggaState.inputStream.constraints.height = newValue.height;
        // this.quaggaState.inputStream.constraints.height = { min: newValue.height };

        setTimeout(() => {
            this.startScanner();
        }, 100);
    }
  },
  mounted: function() {
    this.startScanner();
  },
  destroyed: function() {
    if (this.onDetected) Quagga.offDetected(this.onDetected);
    if (this.onProcessed) Quagga.offProcessed(this.offProcessed);

    this.closeScanner();
  },
  methods: {
    startScanner: function() {
        console.log('this.quaggaState', this.quaggaState);
        this.closeScanner();

        Quagga.init(this.quaggaState, function(err) {
          if (err) {
            return console.error(err);
          }
          Quagga.start();
        });
        Quagga.onDetected(this.onDetected);
        Quagga.onProcessed(this.onProcessed);
    },
    closeScanner: function() {
        try {
            Quagga.stop();
        }catch(e) {
            console.log('not quagga instance started found!');
        }
    }
  }
};
</script>

<style>
/* .viewport {
  position: relative;
} */

/* .viewport canvas,
.viewport video {
  position: absolute;
  left: 0;
  top: 0;
} */

#container {
  width: 640px;
  margin: 20px auto;
  padding: 10px;
}

#interactive.viewport {
  width: 640px;
  height: 480px;
}


#interactive.viewport canvas, video {
    /* position: absolute; */
    position: relative;
  float: left;
  width: 640px;
  height: 480px;
}

#interactive.viewport canvas.drawingBuffer, video.drawingBuffer {
  margin-left: -640px;
}

@media (max-width: 603px) {

  #container {
    width: 300px;
    margin: 10px auto;
    -moz-box-shadow: none;
    -webkit-box-shadow: none;
    box-shadow: none;
  }

  /* #container form.voucher-form input.voucher-code {
    width: 180px;
  } */
}

@media (max-width: 603px) {

  #container {
    width: 300px;
    margin: 10px auto;
    -moz-box-shadow: none;
    -webkit-box-shadow: none;
    box-shadow: none;
  }

  #container form.voucher-form input.voucher-code {
    width: 180px;
  }
}

@media (max-width: 603px) {

  .reader-config-group {
    width: 100%;
  }

  .reader-config-group label > span {
      width: 50%;
  }

  .reader-config-group label > select, .reader-config-group label > input {
      max-width: calc(50% - 2px);
  }

  #interactive.viewport {
    width: 300px;
    height: 300px;
    overflow: hidden;
  }


  #interactive.viewport canvas, video {
    margin-top: -50px;
    width: 300px;
    height: 400px;
  }

  #interactive.viewport canvas.drawingBuffer, video.drawingBuffer {
    margin-left: -300px;
  }


  #result_strip {
    margin-top: 5px;
    padding-top: 5px;
  }

  #result_strip ul.thumbnails > li {
    width: 150px;
  }

  #result_strip ul.thumbnails > li .thumbnail .imgWrapper {
    width: 130px;
    height: 130px;
    overflow: hidden;
  }

  #result_strip ul.thumbnails > li .thumbnail .imgWrapper img {
    margin-top: -25px;
    width: 130px;
    height: 180px;
  }
}

@media (max-width: 603px) {

  .overlay.scanner {
    width: 640px;
    height: 510px;
    padding: 20px;
    margin-top: -275px;
    margin-left: -340px;
    background-color: #FFF;
    -moz-box-shadow: none;
    -webkit-box-shadow: none;
    box-shadow: none;
  }

  .overlay.scanner > .header {
    margin-bottom: 14px;
  }

  .overlay.scanner > .header h4, .overlay.scanner > .header .close {
    line-height: 16px;
  }

  .overlay.scanner > .header .close {
    height: 16px;
    width: 16px;
  }
}
</style>
