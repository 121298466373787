<template>
    <b-modal 
        v-model="showModal" 
        id="modal-lg" 
        size="lg"
        :footer-class="'justify-content-between'"
        ref="modal"
        @hide="onHidden"
        >
        <template #modal-title>
            Lector código de barra
            <span v-if="mode == 'ingreso'" class="badge badge-success">Ingreso</span>
            <span v-if="mode == 'egreso'" class="badge badge-danger">Egreso</span>
        </template>

        <BarcodeReader @codigo="getCodigoSeleccionado"/>

        <template #modal-footer>
            <button type="button" class="btn btn-outline-dark" @click="hideModal">Cerrar</button>
            <button type="button" class="btn btn-success" :class="{ 'disabled': !codigo }"  @click="seleccionarCodigo">
                <font-awesome-icon icon="check" /> Seleccionar código
            </button>
        </template>
    </b-modal>
</template>
<script>
import { library } from '@fortawesome/fontawesome-svg-core';
import { faSave, faSearch, faCheck } from '@fortawesome/free-solid-svg-icons';
import BarcodeReader from './BarcodeReader.vue';

library.add([ faSave, faSearch, faCheck ]);

export default {
	name: 'ModalBarcodeReader',
    components: {
        BarcodeReader
    },
    props: {
        show: {
            type: Boolean,
            default: false
        },
        mode: {
            type: String,
            default: null
        }
    },
	data: function() {
		return {
            showModal: this.show,
            codigo: null
		}
	},
    watch: {
        show: function (nuevo) {
            this.showModal = nuevo;
        }
    },
	methods: {
        onHidden() {
            this.$emit('update:show', false);
            this.$emit('closed', true);
        },
        hideModal() {
            this.$refs['modal'].hide()
        },
        seleccionarCodigo() {
            let self = this;
            self.save = true;

            this.$emit('codigo', this.codigo);
            this.hideModal();
        },
        getCodigoSeleccionado(codigo) {
            this.codigo = codigo;
        }
	},
	mounted: function() {
	}
}
</script>