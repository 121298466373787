<template>
    <div>
        <div class="row mb-3">
            <div class="col-md-4 col-6">
                <h4 class="text-dark d-inline-block mr-1">
                    Modo
                </h4>
                <span v-if="mode == 'ingreso'" class="badge badge-success">Ingreso</span>
                <span v-if="mode == 'egreso'" class="badge badge-danger">Egreso</span>
            </div>
            <div class="col-md-2 col-3">
                <button class="btn btn-success mr-1 align-items-center" @click="mode = 'ingreso'" :class="{'disabled': (mode == 'egreso')}">
                    <font-awesome-icon icon="plus" />
                    <span class="d-none d-md-inline">&nbsp;Ingreso</span>
                </button>
            </div>
            <div class="col-md-2 col-3">
                <button class="btn btn-danger mr-1" @click="mode = 'egreso'" :class="{'disabled': (mode == 'ingreso')}">
                    <font-awesome-icon icon="minus" />
                    <span class="d-none d-md-inline">&nbsp;Egreso</span>
                </button>
            </div>
        </div>
        <div class="row">
            <div class="col-md-4 col-12">
                <div class="col-12 ">
                    <div class="form-group input-group">
                        <input 
                            class="form-control" 
                            type="text" 
                            v-model="form.code" 
                            :class="{'is-invalid': validaciones.code.error}" 
                            required="required" 
                            maxlength="255" 
                            id="code" 
                            @change="buscarPorCodigo"
                        />
                        <div class="input-group-append">
                            <a class="input-group-text text-white bg-primary" @click.prevent="openBarcodeModal" href="#" title="Escanear" >
                                <my-icon name="mdiBarcodeScan" />
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            <div v-if="mostrarBtnCrear" class="col-md-8 text-center text-md-left col-12 pb-3 pt-0">
                <button class="btn btn-info" @click="modals.producto = true">
                    <font-awesome-icon icon="plus" />&nbsp;Crear producto
                </button>
            </div>
        </div>

        <div class="row" v-if="product">
            <div class="card col-12">
                <div class="card-body row">
                    <div class="col-md-3 col-6 mb-2">
                        <span class="font-weight-bold d-block">Nombre</span>
                        {{ product.name }}
                    </div>
                    <div class="col-md-3  col-6  mb-2">
                        <span class="font-weight-bold d-block">Código</span>
                        {{ product.name }}
                    </div>
                    <div class="col-md-3  col-6  mb-2">
                        <span class="font-weight-bold d-block">Marca</span>
                        {{ product.branch.name }}
                    </div>
                    <div class="col-md-3  col-6  mb-2">
                        <span class="font-weight-bold d-block">Descripción</span>
                        {{ product.name }}
                    </div>
                   
                </div>
            </div>
        </div>

        <div class="row">
            <div class="card col-12">
                <div class="card-body">
                    <table>
                        <thead>
                            <tr>
                                <th>Locación</th>
                                <th>Stock</th>
                                <th>Cantidad</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody v-for="(location) in locations" :key="location.id" >
                            <tr :class="{'d-none': !location.visible}">
                                <td class="col-2 px-0"> {{ location.name }} </td>
                                <td class="col-1 px-1"> {{ location.stock }} </td>
                                <td class="col-2 px-1">
                                    <input type="number" class="form-control" :disabled="location.disabled" v-model="location.quantity" />
                                    <!-- <input type="text" class="form-control" :disabled="location.disabled" v-model="locations[index].name" /> -->
                                </td>
                                <!-- <td>
                                    <input type="text" class="form-control" :disabled="location.disabled" v-model="location.name" />
                                </td> -->
                                <td class="col-2 px-1">
                                    <button class="btn btn-xs btn-primary mr-1" 
                                        :disabled="location.disabled" 
                                        @click.prevent="disminuir(location)"
                                        >
                                        <font-awesome-icon icon="minus" />
                                    </button>
                                    <button class="btn btn-xs btn-primary" :disabled="location.disabled" @click.prevent="aumentar(location)">
                                        <font-awesome-icon icon="plus" />
                                    </button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>

        <div class="row mb-3">
            <div class="col-12">
                <hr />
            </div>
            <div class="col-6 text-left">
                <button type="button" class="btn btn-outline-dark" title="Cancelar" @click="cancelar">
                    <font-awesome-icon icon="ban" /> Cancelar
                </button>
            </div>
            <div class="col-6 text-right">
                <button class="btn btn-success" title="Guardar" v-loading-btn="{loading: loadingBtn}" @click="guardar">
                    <font-awesome-icon icon="save" /> Guardar
                </button>
            </div>
        </div>
        <ModalBarcodeReader :show.sync="modals.barcode" :mode="mode"  @closed="closeModal" @codigo="codigoBarcodeReader" />
        <ModalProductosForm :show.sync="modals.producto" :codigo="form.code" @closed="closeModalProducto" @entity="productCreated" />
    </div>
</template>

<script>
import { library } from '@fortawesome/fontawesome-svg-core';
// import WidgetsDropdown from '../widgets/WidgetsDropdown';
import { faArrowCircleRight, faPlus, faMinus, faBarcode, faSave, faBan } from '@fortawesome/free-solid-svg-icons';

library.add([faArrowCircleRight, faPlus, faMinus, faBarcode, faSave, faBan]);

import ModalBarcodeReader from '../productos/ModalBarcodeReader.vue';
import ModalProductosForm from '../productos/ModalProductosForm.vue';
import MyIcon from '../../components/MyIcon.vue';

export default {
    name: 'Inicio',
    components: {
        ModalBarcodeReader,
        ModalProductosForm,
        MyIcon
        // MainChartExample,
        // WidgetsDropdown,
        // WidgetsBrand
    },
    watch: {
        mode: function () {
            this.formatearLocaciones();
            this.cambiarUrl();
        },
    },
    data: () => {
        return {
            loadingBtn: false, 
            mode: 'ingreso',
            locations: [],
            mostrarBtnCrear: false,
            id: null,
            form: {
                code: null
            },
            validaciones: {
                code: {
                    error: false
                }
            },
            product: null,
            modals: {
                barcode: false,
                producto: false
            }
        }
    },
    methods: {
        closeModal() {
            this.modals.barcode = false;
        },
        closeModalProducto() {
            this.modals.producto = false;
        },
        codigoBarcodeReader(codigo) {
            let self = this;
            self.form.code = codigo;
            self.buscarPorCodigo();
        },
        openBarcodeModal() {
            let self = this;
            self.modals.barcode = true;
        },
        productCreated(entity) {
            console.log('productCreated entity', entity);
            let self = this;
            if(entity.code) {
                self.form.code = entity.code;
            }
            self.buscarPorCodigo(false);
        },
        cambiarUrl() {
            this.$router.push({query: {mode: this.mode, code: this.form.code}});
        },
        aumentar(location) {
            location.quantity++;
        },
        disminuir(location) {
            if(location.quantity > 0) {
                location.quantity--;
            }
        },
        cancelar() {
            const self = this;

            self.mostrarBtnCrear = false;
            self.limpiarBusqueda();
        },
        formatearLocaciones() {
            const self = this;

            if(self.product) {
                switch(self.mode) {
                    case 'ingreso':
                        self.locations.map(location => {
                            location.disabled = false;
                            location.quantity = 0;
                            // location.location_stock_id = null;
                            location.visible = true;
                        });
                        break;
                    case 'egreso':
                        self.locations.map(location => {
                            location.quantity = 0;
                            if(!location.location_stock_id) {
                                location.visible = false;
                            } else {
                                location.disabled = false;
                            }
                        });
                        break;
                }                
            }
        },
        limpiarBusqueda(limpiarCodigo = true) {
            const self = this;

            self.product = null;
            if(limpiarCodigo) {
                self.form.code = null;
            }
            self.locations.map(location => {
                location.stock = '-';
                location.disabled = true;
                location.quantity = 0;
                location.location_stock_id = null;
                location.visible = true;
            });
        },
        buscarPorCodigo(cambiarUrl = true) {
            let self = this;

            if(cambiarUrl) {
                self.cambiarUrl();
            }
            self.axios.get('products/find', {
                params: {
                    code: self.form.code,
                    'with-locations': 1
                }
            })
            .then(function (response) {
                console.log('response', response.data);
                if(response.data) {
                    self.mostrarBtnCrear = false;
                    self.$toastr.s(`Código: <b>${self.form.code}</b> encontrado!`, "Correcto!");
                    self.product = response.data;

                    response.data.locationStocks.map(locationStock => {
                        console.log('locationStock', locationStock);
                        const location = self.locations.find(location => location.id === locationStock.location_id);

                        location.location_stock_id = locationStock.id;
                        location.stock = locationStock.stock;
                        console.log('location', location);
                    });
                    // setTimeout(() => {
                        self.formatearLocaciones();
                    // }, 200);
                } else {
                    self.product = null;
                    self.mostrarBtnCrear = true;
                    self.$toastr.e(`Artículo código: <b>${self.form.code}</b> no encontrado!<br>Puede crearlo desde el botón`, "Error");
                    self.limpiarBusqueda(false);
                }
            }).catch(function (error) {
                self.$toastr.e(error, "Exception");
            });
        },
        guardar() {
            const self = this;
            self.loadingBtn = true;
            let locationsQuantity = [];

            self.locations.map(location => {
                if(location.quantity > 0) {
                    locationsQuantity.push({
                        location_id: location.id,
                        location_stock_id: location.location_stock_id,
                        quantity: location.quantity
                    });
                }
            });

            const user = JSON.parse(self.$localStorage.get('stock_usuario'));
            self.axios.post('location-stocks', locationsQuantity, {
                params: {
                    mode: self.mode,
                    'product-id': self.product.id,
                    'user-id': user.id
                }
            })
            .then(function(response) {
                self.loadingBtn = false;
                if(response.error) {
                    self.$toastr.e(response.message, "Error");
                } else {
                    self.$toastr.s(response.message, "Correcto");
                    self.buscarPorCodigo();
                }
            })
            .catch(function (error) {
                self.loadingBtn = false;
                self.$toastr.e(error, "Exception");
            });
            
            console.log('locationsQuantity', locationsQuantity);
            console.log('locations', self.locations);
        }
    },
    beforeMount: function(){
        const self = this;

console.log('self.axios', self.axios.defaults);
// console.log('self.axiosScapper', self.axiosScapper.defaults);

        self.axios.get('locations/list')
        .then(function (response) {
            response.data.map(location => {
                let locacionItem = { 
                    ...location, 
                    stock: '-',
                    disabled: true,
                    quantity: 0,
                    location_stock_id: null,
                    visible: true
                };
                self.locations.push(locacionItem);
            });
        }).catch(function (error) {
            self.$toastr.e(error, "Exception");
        });
    }
}
</script>
<style>
</style>